import React from "react"
import '../styles/index.scss'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import pageStyles from './page.module.scss'


const options = {
    renderNode: {
        'embedded-asset-block': (node) => {
            return (
                <div className={pageStyles.pageImageContainer}>
                    <img 
                        src={node.data.target.fields.file['en-US'].url} 
                        alt={node.data.target.fields.title}
                        className={pageStyles.pageImage} />
                    <h5 className={pageStyles.pageImageCaption}>{node.data.target.fields.description['en-US']}</h5>
                </div>
            )
        }
    },
}


const BlogPost = (props) => {
    const { title, content, publishDate } = props.data.contentfulBlogPost;
    let postDate = new Date(publishDate)
    return (
        <Layout>
            <h3>{title}</h3>
            <p className={pageStyles.publishDate}>Published {postDate.toDateString()} at {(postDate.getHours() % 12 === 0 ? 12 : postDate.getHours() % 12)}:{(postDate.getMinutes())} {postDate.getHours >= 12 ? 'pm' : 'am'}</p>
            {content && content.json && documentToReactComponents(content.json, options)}
        </Layout>
    );
}

export default BlogPost;

export const query = graphql`
    query ($slug: String!) {
        contentfulBlogPost(slug: { eq: $slug } ) {
            title
            publishDate
            content {
                json
            }
        }
    }
`
